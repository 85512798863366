import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import ReactTextTransition, { presets } from "react-text-transition"

// images
import Design from "../images/theme-photos-CGpifH3FjOA-unsplash.png"
import Scott from "../images/scott-graham.png"
// import Shake from "../images/handshake.png"
import Shake from "../images/contract-hire.jpg"
import Shake2 from "../images/shakehand2.png"
import Payroll from "../images/pexels-oleg-magni-2058144.jpg"
import Area from "../components/section/Area"
import icon1 from "../images/contact/icon1.png"
import icon2 from "../images/contact/icon2.png"
import icon3 from "../images/contact/icon3.png"

const texts = [
  "Project Manager",
  "ERP Consultant",
  "Data Engineer",
  "Software Developer",
]

class PeerNetwork extends React.Component {
  state = {
    textIndex: 0,
    textFastIndex: 0,
    paragraphIndex: 0,
  }

  componentDidMount() {
    setInterval(() => {
      this.setState({
        textIndex: this.state.textIndex + 1,
      })
    }, 2000)
    setInterval(() => {
      this.setState({
        textFastIndex: this.state.textFastIndex + 1,
      })
    }, 150)
  }

  render() {
    return (
      <Layout title="Recuiting Services">
        <SEO title="Recuiting Services" />
        <div id="rs-page">
          <div className="page-headline">
            <Container>
              <h1 className="bottom-underline text-center text-blue">
                RECRUITING SERVICES
              </h1>

              <Row>
                <Col md={7} className="align-self-center">
                  <h2>
                    Hire your next <br />
                    <ReactTextTransition
                      text={texts[this.state.textIndex % texts.length]}
                      spring={presets.wobbly}
                      noOverflow
                      className="rotating-titles"
                    />
                    today!
                  </h2>
                </Col>
                <Col md={5} className="align-self-center">
                  <img src={Design} className="img-fluid" alt="designer" />
                </Col>
              </Row>
            </Container>
          </div>
          <Container>
            <div className="py-100">
              <Row className="justify-content-center">
                <Col lg={9}>
                  <p>
                    It’s no secret that the technology industry is booming and
                    it’s becoming increasingly harder to attract the right
                    technical talent to fit your business needs.
                  </p>
                  <h3 className="my-5 text-center ">
                    That’s where PeerSource comes in.
                  </h3>
                  <p>
                    We are uniquely set up to find the talent you’re looking for
                    and attract them to your company. How so? For exclusive
                    clients our network of SMEs will conduct a tech screen prior
                    to us submitting a candidate over, ensuring you only receive
                    technically-qualified candidates. Our sister company
                    OnePoint offers a range of W2 benefits and services that
                    most recruiting companies are simply unable to offer,
                    thereby keeping your consultants happy. And most of all, our
                    commitment to excellent service not only to your needs but
                    to our candidates’ needs will ensure that the entire
                    recruiting experience is a positive & beneficial one.
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
          <Area />
          <Container>
            <div className="py-100">
              <Row className="justify-content-center">
                <Col lg={9}>
                  <h3 className="my-5 text-center">
                    Types of service we provide:
                    <br />
                    Contract / Contract-to-Hire / Direct Hire
                  </h3>
                  <h4 className="my-5 text-center text-sky-blue">
                    The industries we have serviced:
                  </h4>
                  <Row className="justify-content-center">
                    <Col md={4}>
                      <ul className="checked-list lead">
                        <li>Accounting</li>
                        <li>Cable</li>
                        <li>Distribution</li>
                        <li>eDiscovery</li>
                        <li>Education</li>
                        <li>Energy</li>
                        <li>Finance</li>
                      </ul>
                    </Col>
                    <Col md={4}>
                      <ul className="checked-list lead">
                        <li>Government</li>
                        <li>Healthcare</li>
                        <li>Internet of Things</li>
                        <li>Manufacturing</li>
                        <li>Retail</li>
                        <li>Software</li>
                        <li>Telecommunications</li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Container>
          <div id="contract" style={{ backgroundColor: "#0d2e4f" }}>
            <Container fluid>
              <Row>
                <Col
                  md={6}
                  style={{
                    background: `url(${Scott})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                  }}
                ></Col>
                <Col md={6}>
                  <div className="px-big">
                    <h3 className="text-sky-blue mb-5">CONTRACT</h3>
                    <p className="text-white">
                      Whether you have a short-term project or you have a
                      multi-year long program, we’re here to help you find the
                      right candidate for the job. In addition, our Account
                      Managers and Recruiters maintain contact with our
                      consultants throughout the entire duration of the
                      contract, making sure that they have everything they need
                      to get the job done for you. We'll also keep in close
                      contact with the hiring manager to check in on our
                      services.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div id="contract-to-hire">
            <Container fluid>
              <Row>
                <Col md={6}>
                  <div className="px-big">
                    <h3 className="text-blue-left mb-5">CONTRACT-TO-HIRE</h3>
                    <p>
                      Being able to find talented full-time employees who also
                      fit well within an existing corporate culture is one of
                      the most significant challenges that companies face today.
                      In order to meet this demand, we provide our clients with
                      the option to hire our employees on a Contract-to-Hire
                      basis. This staffing model offers our clients the
                      opportunity to review our employees on a contract basis to
                      ensure they are a strong technical and cultural fit for
                      the company prior to hiring them on directly as a fulltime
                      employee. We always allow our contractors to convert,
                      never locking our clients into a contract that exclusively
                      keeps them as consultants.
                    </p>
                  </div>
                </Col>
                <Col
                  md={6}
                  style={{
                    background: `url(${Shake})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                  }}
                ></Col>
              </Row>
            </Container>
          </div>
          <div id="direct-hire" style={{ backgroundColor: "#0d2e4f" }}>
            <Container fluid>
              <Row>
                <Col
                  md={6}
                  style={{
                    background: `url(${Shake2})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                  }}
                ></Col>
                <Col md={6}>
                  <div className="px-big">
                    <h3 className="text-sky-blue mb-5">DIRECT HIRE</h3>
                    <p className="text-white">
                      We will partner with you to find the best fit for the
                      position. We use our rigorous qualification process to
                      identify the right person for your full-time position,
                      just like we do for a contract or contract-to-hire
                      positions. This service is available from either a
                      Contingent or Retained basis.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* <div id="payroll-services">
            <Container fluid>
              <Row>
                <Col md={6}>
                  <div className="px-big">
                    <h3 className="text-blue-left mb-5">PAYROLL SERVICES</h3>
                    <p>
                      PeerSource offers comprehensive payroll services in
                      partnership with our sister company OnePoint Employer
                      Solutions.
                    </p>
                    <Link to="/payroll-services" className="common-btn">
                      Learn More
                    </Link>
                  </div>
                </Col>
                <Col
                  md={6}
                  style={{
                    background: `url(${Payroll})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "bottom right",
                  }}
                ></Col>
              </Row>
            </Container>
          </div> */}

          <Container
            className="px-0 contact-vp"
            style={{ backgroundColor: "#fff" }}
          >
            <div
              style={{
                padding: "100px 20px",
                boxShadow: "0px 3px 52px #00000057",
              }}
            >
              <Row>
                <Col lg="4" className="text-center my-3">
                  <img src={icon1} alt="" />
                  <h3 className="mt-3" style={{ color: "#176588" }}>
                    We learn about you
                  </h3>
                </Col>
                <Col lg="4" className="text-center my-3">
                  <img src={icon3} alt="" />
                  <h3 className="mt-3" style={{ color: "#176588" }}>
                    We build your team
                  </h3>
                </Col>

                <Col lg="4" className="text-center my-3">
                  <img src={icon2} alt="" />
                  <h3 className="mt-3" style={{ color: "#176588" }}>
                    You keep your culture
                  </h3>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default PeerNetwork
